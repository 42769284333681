import React, {Fragment, useEffect, useState} from "react";
import PropTypes                              from "prop-types";
import CreatableSelect  from 'react-select/creatable';
import {diary}          from "../../index";
import {Spinner}        from "../dom/Spinner";
import {ErrorContainer} from "../dom/ErrorContainer";

const axios = require("axios").default;

export const SchoolSelectAndAdd = props => {
    const [schools, setSchools] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
            loadSchools();
    }, []);

    const loadSchools = () => {
        axios.get('/api/schools/', {params: {sortName: 'asc'}})
             .then((response) => {
                 setSchools(response.data);
             })
             .catch((error) => {
                 setErrors(errors => [...errors, error.response.data]);
             });
    };

    const handleNewSchoolCreate = (inputValue) => {
        diary.getConfirm("Записать новое образовательное учреждение?")
             .then(
                 //success
                 () => {
                     saveSchool(inputValue);
                 },
             );
    };

    const isValidNewOption = (inputValue, selectValue, selectOptions) => {
        return !(inputValue.trim().length === 0 ||
            selectOptions.find(option => option.name === inputValue));
    };

    const schoolSelectStyles = {
        menu: (provided, state) => ({
            ...provided,
            zIndex: 9999,
        }),
    };


    const saveSchool = (schoolName) => {
        setLoading(true);
        axios.put('/api/schools/',
            {
                "element": {"name": schoolName},
            },
        )
             .then((response) => {
                 let newSchool = {
                     ID: response.data,
                     desc: "",
                     name: schoolName,
                 }
                 props.onSchoolChange?.(newSchool);
                 loadSchools();
                 setLoading(false);
             })
             .catch((error) => {
                 setErrors(errors => [...errors, "Не удалось выполнить запрос"]);
                 setLoading(false);
             });
    };

    if (isLoading) {
        return <Spinner/>;
    }

    return (
        <Fragment>
            {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}
            <div className="form-group">
                <label htmlFor={"school_id"}>Место обучения</label>
                <CreatableSelect
                    isClearable
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    onCreateOption={handleNewSchoolCreate}
                    onChange={props.onSchoolChange}
                    id={"school_id"}
                    placeholder="Выбрать или добавить ОУ"
                    value={props.element || {}}
                    options={schools}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.ID}
                    getNewOptionData={(inputValue, optionLabel) => ({
                        id: inputValue,
                        name: optionLabel,
                    })}
                    styles={schoolSelectStyles}
                    isValidNewOption={isValidNewOption}
                />
            </div>
        </Fragment>
    );

};

SchoolSelectAndAdd.propTypes = {
    element: PropTypes.object,
    onSchoolChange: PropTypes.func,
};



