import React, {Fragment, useState} from "react";
import PropTypes                   from "prop-types";

export const CertificateView = props => {
    const certificate = props.certificate;

    if (!certificate) {
        return ("");
    }

    return (

        <div className="row">
            <div className="col-12 col-md-4">
                # {certificate.number}
            </div>
            <div className="col-12 col-md-8">
                {certificate.costsDisplay ?
                    <span>Изменение стоимости: {certificate.costsDisplay}</span>
                    :
                    <span>Не установлена стоимость сертификата</span>
                }
            </div>
        </div>
    );
};

CertificateView.propTypes = {
    certificate: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};
