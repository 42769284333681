import React, {Fragment, useEffect, useState} from "react";
import PropTypes                              from "prop-types";
import {DefaultDatepicker}                    from "../dom/DefaultDatepicker";
import {Spinner}                              from "../dom/Spinner";
import {ErrorContainer}                       from "../dom/ErrorContainer";

const axios = require('axios').default;

export const CertificateForm = props => {
    const [element, setElement] = useState(props.element);
    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
    });

    const handleChange = (e) => {
        const {id, value} = e.target;
        setElement({...element, [id]: value});
    };

    const onButtonCancelClick = (e) => {
        props.handleCancel();
    };

    const setDate = (date) => {
        console.log(date);
        if (date) {
            setElement({...element, "date": date.toLocaleString()});
        }
    };

    const handleSubmit = (event) => {
        setLoading(true);
        setErrors([]);
        if (!element.number) {
            setErrors(errors => [...errors, "Не установлен Номер сертификата!"]);
            setLoading(false);
            return false;
        }
        if (!element.date) {
            setErrors(errors => [...errors, "Не установлена дата сертификата!"]);
            setLoading(false);
            return false;
        }
        if (!element.cost) {
            setErrors(errors => [...errors, "Не установлена стоимость сертификата!"]);
            setLoading(false);
            return false;
        }
        axios.put('/api/certificates/save/',
            {
                element: {
                    "id": element.id,
                    "student_id": props.studentId,
                    "number": element.number,
                    "costs": element.costs,
                    "date": element.date,
                    "cost": element.cost,
                },
            },
        )
             .then((response) => {
                 setLoading(false);
                 props.onCertificateChange(response.data);
                 props.handleCancel();
             })
             .catch((error) => {
                 setLoading(false);
                 setErrors(errors => [...errors, ["Не удалось отправить форму!", error.response.data.errors]]);
             });
    };

    if (isLoading) {
        return <Spinner/>;
    }

    return (
        <div className="row align-items-center">
            {errors.length > 0 ?
                <div className="col-12 small">
                    <ErrorContainer arErrors={errors}/>
                </div>
                :
                ""
            }
            <div className="col-12 col-md-3">
                <div className="form-group">
                    <label htmlFor="number">Номер сертификата<span
                        className="required"> </span></label>
                    <input id="number"
                           className="form-control"
                           name="certificate[number]"
                           type="text"
                           autoComplete={"off"}
                           value={element.number || ""}
                           required={true}
                           onChange={handleChange}/>
                </div>
            </div>

            <div className="col-12 col-md-3">
                <div className="form-group">
                    <label className="display_block" htmlFor="date_from">Дата установки цены
                        <span className="required"> </span>
                    </label>
                    <DefaultDatepicker
                        id={"date_from"}
                        name={"element[date_from]"}
                        dateFormat={"dd.MM.yyyy"}
                        maxDate={""}
                        required={true}
                        selected={element.date || ""}
                        onChange={setDate}/>
                </div>
            </div>

            <div className="col-12 col-md-3">
                <div className="form-group">
                    <label className="display_block" htmlFor="cost">Стоимость сертификата
                        <span className="required"> </span>
                    </label>
                    <input className="form-control" id="cost" name="element[cost]"
                           onChange={handleChange}
                           autoComplete={"off"}
                           required={true}
                           type="number"
                           value={element.cost || ""}/>
                </div>
            </div>

            <div className="col-12 col-md text-end text-nowrap">
                <button className="btn btn-success btn-sm me-3"
                        title={"записать сертификат"}
                        onClick={handleSubmit}>
                    <i className="fas fa-check me-2"></i>Записать
                </button>
                <button className="btn btn-secondary btn-sm"
                        title={"закрыть форму"}
                        onClick={onButtonCancelClick}>
                    <i className="fas fa-times me-2"></i>Отменить
                </button>
            </div>

            {element ?
                <div className="col-12">
                    Изменение стоимости: {element.costsDisplay}
                </div>
                : ""
            }
        </div>
    );
};

CertificateForm.propTypes = {
    element: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    studentId: PropTypes.string,
    onCertificateChange: PropTypes.func,
    handleCancel: PropTypes.func,
};
