import React, {Fragment, useEffect, useRef, useState} from "react";
import PropTypes        from "prop-types";
import {Spinner}        from "../dom/Spinner";
import {ErrorContainer} from "../dom/ErrorContainer";
import {legacy}         from "../../legacy";
import {Contact}        from "./Contact";

const axios = require("axios").default;

export const ContactsSection = props => {
    const [studentId, setStudentId] = useState(props.studentId);
    const [contacts, setContacts] = useState(props.contacts);
    const [isLoaded, setLoaded] = useState(true);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (studentId) {
            loadData();
        }
    }, [studentId]);

    const onAddButtonClick = (event) => {
        event.preventDefault();
        legacy.studentFillContactsForm(studentId, loadData);
    };

    const loadData = () => {
        setLoaded(false);
        axios.get(`/api/students/${studentId}/contacts/`)
             .then((response) => {
                 setContacts(response.data);
                 setLoaded(true);
                 return response.data;
             })
             .catch((errors) => {
                 setLoaded(true);
                 setErrors(errors => [...errors, "Не удалось выполнить запрос!"]);
                 if (app.debug) {
                     throw new Error(errors);
                 }
             });
    };

    return (
        <Fragment>
            {errors.length > 0 ?
                <ErrorContainer arErrors={errors}/>
                :
                <Fragment>
                    {!isLoaded ?
                        <Spinner/>
                        : (
                            <Fragment>
                                <div className="row mb-3 pb-2">
                                    <div className="col-12 col-md-7">
                                        <h4>Контакты слушателя</h4>
                                    </div>
                                    <div className="col-12 col-md-5 text-end">
                                        {studentId ?
                                            <a className="btn btn-link btn-sm text-secondary"
                                               onClick={onAddButtonClick}>
                                                <i className="fas fa-plus me-2"></i>
                                                добавить контакт
                                            </a>
                                            :
                                            (
                                                    <div className={"text-info"}>
                                                        Чтобы добавить контакты - запишите слушателя!
                                                    </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    {contacts.length > 0 ?
                                        <Fragment>
                                            {contacts.map((contact, index) => (
                                                    <div key={contact.ID}
                                                         className={"col-12 col-md-6 border-bottom border-light mb-3"}>
                                                        <Contact contact={contact}/>
                                                    </div>
                                                ),
                                            )}
                                        </Fragment>
                                        : (
                                            <div className={"col-12"}>
                                                <div className={"text-info"}>
                                                    Контакты не установлены
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </Fragment>
                        )}
                </Fragment>
            }
        </Fragment>
    );

};

ContactsSection.propTypes = {
    studentId: PropTypes.string,
    contacts: PropTypes.array,
};


