import React, {Fragment, useEffect, useState} from "react";
import {Spinner}                              from "../dom/Spinner";
import {ErrorContainer}                       from "../dom/ErrorContainer";
import {PersonSection}                        from "./PersonSection";
import {Checkbox}                             from "../dom/Checkbox";
import PropTypes                              from "prop-types";
import {UserSection}                          from "./UserSection";
import {SchoolSelectAndAdd}                   from "./SchoolSelectAndAdd";
import {ElementsContext}                      from "../enums/ElementsContext";
import _                                      from "lodash";
import {ContactsSection}                      from "./ContactsSection";
import {CertificateSection}                   from "./CertificateSection";

const axios = require("axios").default;

export const StudentForm = props => {
    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [element, setElement] = useState(props.element || {});
    const [person, setPerson] = useState(props.element?.person || {});
    const [useSchool, setUseSchool] = useState(!!props.element?.school);
    const formId = "student_edit_form";

    useEffect(() => {
    });

    useEffect(() => {
        setValidation();
    }, []);

    const setValidation = () => {
        $("#" + formId).validate(
            {
                rules: {
                    "element[person_id]": {
                        required: true,
                    },
                    "element[login]": {
                        required: true,
                    },
                },
            },
        );
    };

    const applySubmit = (event) => {
        event.applySubmit = true;
        handleSubmit(event);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let $form = $("#" + formId);
        if (!$form.valid()) {
            return false;
        }
        if (element.user && !element.user_id) {
            setErrors(errors => [...errors, "Не записан пользователь!"]);
            return false;
        }
        setLoading(true);
        axios.put('/api/students/', {element: element})
             .then((response) => {
                 setSuccess(true);
                 setLoading(false);
                 if (props.backUrl && !event.applySubmit) {
                     window.location.href = props.backUrl;
                 } else {
                     window.location.href = `/students/${response.data}/edit/`;
                 }
             })
             .catch((errors) => {
                 setElement(errors.data.element);
                 setErrors(errors => [...errors, errors.data.errors]);
                 setLoading(false);
             });
    };

    const onPersonChange = (person) => {
        let newPersonData = {
            person: person,
            person_id: person.ID,
            user: person.user,
            user_id: person.user?.ID,
        };
        setElement({...element, ...newPersonData});
        setPerson(person);
    };

    const onUserChange = (user) => {
        let newUserData;
        if (!_.isEmpty(user)) {
            newUserData = {
                user: user,
                user_id: user.ID,
            };
        } else {
            newUserData = {
                user: null,
                user_id: null,
            };
        }
        setElement({...element, ...newUserData});
    };

    const onCertificateChange = (certificate) => {
        let newCertificateData = {
            certificate: certificate,
        };
        setElement({...element, ...newCertificateData});
    };

    const HandleCheckBoxChange = async (event) => {
        event.persist();
        let isChecked = event.target.checked;
        // если сняли галку, но там были сведения - их нужно удалить
        if (!isChecked && element.school) {
            const confirmRes = await app.getConfirm("Данные по месту обучения слушателя будут удалены?");
            if (!confirmRes) {
                return false;
            }
            let newSchoolData = {
                school_id: null,
                school: null,
                smena: null,
                shift: null,
                study_year: null,
            };
            setElement({...element, ...newSchoolData});
        }
        setUseSchool(isChecked);
    };

    const handleChange = (e) => {
        const {id, value} = e.target;
        if (id === "shift") {
            setElement({...element, smena: value});
        }
        setElement({...element, [id]: value});
    };

    const onSchoolChange = (school) => {
        if (!school) {
            return;
        }
        let newSchoolData = {
            school_id: school.ID,
            school: school,
        };
        setElement({...element, ...newSchoolData});
    };

    const SuccessMessage = cProps => (
        <div className={"alert alert-success"}>
            Слушатель успешно записан!
        </div>
    );

    if (isLoading) {
        return <Spinner/>;
    }

    return (
        <Fragment>
            <PersonSection
                context={ElementsContext.STUDENT}
                onPersonChange={onPersonChange}
                onPersonSet={(person) => setPerson(person)}
                personId={element.person_id || ""}/>

            {person?.ID ?
                <form id={formId} onSubmit={handleSubmit} method={"POST"}>

                    <input name="element[ID]" type="hidden" value={element.ID || ""}/>
                    <input name="element[person_id]" type="hidden" value={element.person_id || ""}/>
                    <input name="element[user_id]" type="hidden" value={element.user_id || ""}/>

                    {success ? <SuccessMessage/> : ""}
                    {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}

                    <h2>Сведения о слушателе</h2>

                    <div className={"mb-2 p-3 border border-light shadow-sm rounded-lg mb-3"}>
                        <div className={"mb-3"}>
                            <Checkbox
                                label={"Использовать место обучения"}
                                name={"useSchool"}
                                selected={useSchool}
                                onChange={HandleCheckBoxChange}/>
                        </div>
                        {useSchool ?
                            <Fragment>
                                {element.person ?
                                    <Fragment>
                                        <div className="row">
                                            <div className="col">
                                                <SchoolSelectAndAdd
                                                    element={element.school || {}}
                                                    onSchoolChange={onSchoolChange}
                                                />
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <label className="display_block"
                                                           htmlFor="study_year">Класс/курс</label>
                                                    <input className="form-control"
                                                           id="study_year"
                                                           name="element[school][study_year]"
                                                           onChange={handleChange}
                                                           autoComplete={"off"}
                                                           type="text"
                                                           value={element.study_year || ""}/>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <label className="display_block" htmlFor="shift">Смена</label>
                                                    <input className="form-control"
                                                           id="shift"
                                                           name="element[school][shift]"
                                                           onChange={handleChange}
                                                           autoComplete={"off"}
                                                           type="text"
                                                           value={element.shift || ""}/>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                    :
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <div className={"alert alert-danger"}>необходимо выбрать физлицо</div>
                                        </div>
                                    </div>
                                }
                            </Fragment>
                            : ""}
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3 shadow-sm rounded-lg w-100 p-3">
                                <ContactsSection
                                    studentId={element.ID || ""}
                                    contacts={element.contacts || []}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={"row mb-2"}>
                        <div className={"col-12"}>
                            <div className="card mb-3 shadow-sm rounded-lg p-3">
                                <CertificateSection
                                    studentId={element.ID}
                                    certificate={element.certificate}
                                    edit={false}
                                    onCertificateChange={onCertificateChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={"row mb-2"}>
                        <div className={"col-12"}>
                            <div className="card mb-3 shadow-sm rounded-lg p-3">
                                <UserSection
                                    user={element.user}
                                    person={person || {}}
                                    edit={false}
                                    context={ElementsContext.STUDENT}
                                    onUserChange={onUserChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="text-end">
                        <button className="btn btn-primary me-2"
                                name="submit"
                                onClick={handleSubmit}
                                value="Y">
                            Сохранить и закрыть
                        </button>
                        <button className="btn btn-outline-secondary me-2"
                                name="apply"
                                onClick={applySubmit}
                                value="Y">
                            Записать
                        </button>
                        <a href={props.backUrl} className="btn btn-secondary btn-cancel">отменить</a>
                    </div>

                </form>
                :
                ""
            }
        </Fragment>
    );

};

StudentForm.propTypes = {
    formAction: PropTypes.string,
    backUrl: PropTypes.string,
    element: PropTypes.object,
};
