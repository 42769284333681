import React, {Fragment, useEffect, useRef, useState} from "react";
import PropTypes                                      from "prop-types";
import {Spinner}                                      from "../dom/Spinner";
import {ErrorContainer}                               from "../dom/ErrorContainer";
import {CertificateView}                              from "./CertificateView";
import {CertificateForm}                              from "./CertificateForm";
import {HelpButton}                                   from "../dom/HelpButton";

const axios = require("axios").default;

export const CertificateSection = props => {
    const [certificate, setCertificate] = useState(props.certificate);
    const [edit, setEdit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (props.studentId && !certificate) {
            loadByStudentId();
        }
    }, []);

    const onAddButtonClick = (event) => {
        setEdit(true);
    };

    const onEditButtonClick = () => {
        setEdit(true);
    };

    const handleCancel = () => {
        setEdit(false);
    };

    const onCertificateChange = (certificateId) => {
        loadById(certificateId);
    };

    const loadByStudentId = () => {
        setLoading(true);
        axios.get(`/api/students/${props.studentId}/certificate/`)
             .then((response) => {
                 setCertificate(response.data);
                 setLoading(false);
             })
             .catch((errors) => {
                 setLoading(false);
                 setErrors(errors => [...errors, "Не удалось выполнить запрос!"]);
                 if (app.debug) {
                     throw new Error(errors);
                 }
             });
    };

    const loadById = (certificateId) => {
        setLoading(true);
        axios.get(`/api/certificates/${certificateId}/`)
             .then((response) => {
                 setCertificate(response.data);
                 setLoading(false);
             })
             .catch((errors) => {
                 setLoading(false);
                 setErrors(errors => [...errors, "Не удалось выполнить запрос!"]);
                 if (app.debug) {
                     throw new Error(errors);
                 }
             });
    };

    if (isLoading) {
        return <Spinner/>;
    }

    if (errors.length > 0) {
        return <ErrorContainer arErrors={errors}/>;
    }

    return (
        <Fragment>
            <div className="row mb-3 pb-2 align-items-center">
                <div className="col-12 col-md-7">
                    <h4>Сертификат ПФДО</h4>
                </div>
                <div className="col-12 col-md-4 text-end">
                    {props.studentId && !certificate && !edit ?
                        <a className="btn btn-link btn-sm text-secondary"
                           onClick={onAddButtonClick}>
                            <i className="fas fa-plus me-2"></i>
                            добавить сертификат
                        </a>
                        : ""}
                    {certificate ?
                        <div className="col text-end">
                            {!edit ?
                                <a className="text-success me-2"
                                   href={"#"}
                                   onClick={onEditButtonClick}>
                                    <i className="fas fa-pencil-alt me-2"></i>
                                </a>
                                : ""}
                            <a className="text-danger"
                               href={`/certificates/${certificate.id}/delete/`}
                               data-action="delete"><i className="fas fa-trash me-2"></i></a>
                        </div>
                        : ""}
                </div>
                <div className="col-12 col-md">
                    <HelpButton dataLoadUrl={"certificate.edit.help"}/>
                </div>
            </div>
            {edit ?
                <CertificateForm
                    studentId={props.studentId}
                    element={certificate}
                    onCertificateChange={onCertificateChange}
                    handleCancel={handleCancel}/>
                :
                <CertificateView
                    certificate={certificate}/>
            }
        </Fragment>
    );

};

CertificateSection.propTypes = {
    studentId: PropTypes.string,
    certificate: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    edit: PropTypes.bool,
};


