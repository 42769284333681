import {Pages}       from "./Pages";
import "jquery-validation";
import "jquery-validation/dist/additional-methods";
import "jquery-validation/dist/localization/messages_ru";
import React         from "react";
import {Utils}       from "../Utils";
import ReactDOM      from "react-dom";
import {StudentForm} from "../components/StudentForm";

export class StudentsEdit extends Pages {

    initPageHandlers(params) {
        super.initPageHandlers(params);
        this.data = window.diaryJsData;
        ReactDOM.render(
            <StudentForm
                formAction={this.data.formAction}
                backUrl={this.data.backUrl}
                element={this.data.element}/>,
            document.getElementById('studentForm')
        );


        // @deprecated - проверить и убрать
        var $bDate = $("#studentBirthDate"),
            bDate = getDate($bDate),
            dateFormat = app.config.DEFAULT_DATEPICKER_DATE_FORMAT,
            defaultDate = (bDate === null) ? "-8y" : bDate;

        $bDate
            .datepicker({
                dateFormat: dateFormat,
                defaultDate: defaultDate,
                changeMonth: true,
                changeYear: true,
                beforeShow: Utils.correctInputPosition,
            });


        function getDate(element) {
            try {
                return  $.datepicker.parseDate(dateFormat, element.value);
            } catch (error) {
                return  null;
            }
        }

        $("form").validate(
            {
                rules: {
                    "element[f]": {
                        required: true,
                    },
                    "element[i]": {
                        required: true,
                    },
                    "element[login]": {
                        required: true,
                    },
                    "element[pass]": {
                        required: true,
                    },
                    "element[phone]": {
                        require_from_group: [1, '.contacts-group'],
                    },
                    "element[email]": {
                        email: true,
                        require_from_group: [1, '.contacts-group'],
                    },
                },
                messages: {
                    "element[email]": {
                        require_from_group: "Одно из полей (телефон или email) должно быть заполнено!",
                    },
                    "element[phone]": {
                        require_from_group: "Одно из полей (телефон или email) должно быть заполнено!",
                    },
                },
            },
        );


    }

}
